import Translate from "../../components/Translate";

//Enquando não resolve como vai ser passado o centro de custo de forma automatica
var CenterCost = [];
CenterCost["10"] = 8;
CenterCost["54.144.83.229"] = 49;
CenterCost["localhost"] = 377;
CenterCost['85fc-45-160-127-8'] = 4;
CenterCost["demo"] = 49;
CenterCost["aboz"] = 3;
CenterCost["aboz2021"] = 4;
CenterCost["conline"] = 2;
CenterCost["sbnr"] = 34;
CenterCost["sbd"] = 82;
CenterCost["sbacvrj"] = 3;
CenterCost["srb"] = 4;
CenterCost["abes"] = 8;
CenterCost["interevent"] = 9;
CenterCost["cpe2020"] = 8;
CenterCost["infectorio"] = 6;
CenterCost["viicombined"] = 4;
CenterCost["aborl2022"] = 5;
CenterCost["sgorj"] = 5;
CenterCost["cbed"] = 5;
CenterCost["confra2020"] = 3;
CenterCost["simposiodedermatologiapediatrica"] = 134;
CenterCost["sbacvpe"] = 3;
CenterCost["higiexpoecongressoabrafac"] = 3;
CenterCost["apr"] = 3;
CenterCost["smc"] = 5;
CenterCost["sobope2022"] = 4;
CenterCost["iah2021"] = 3;
CenterCost["fortedecopacabana"] = 4;
CenterCost["oabmg"] = 3;
CenterCost["nefrologia2021"] = 3;
CenterCost["medtropplay"] = 3;
CenterCost["abramet"] = 3;
CenterCost["immuno2021"] = 4;
CenterCost["pan2021"] = 4;
CenterCost["encob"] = 3;
CenterCost["cbp"] = 4;
CenterCost["wft2021"] = 130;
CenterCost["riopipeline2021"] = 5;
CenterCost["rog22"] = 3;
CenterCost["fita2021"] = 28;
CenterCost["falan2022"] = 26;
CenterCost["simposiosaneamento"] = 4;
var EventsAsbran = ["asbran", "confelanyd"]
CenterCost["asbran"] = 4;
CenterCost["confelanyd"] = 5;
var EventsMezcla = ["mezcla", "pneumoinrio"]
CenterCost["mezcla"] = 9;
CenterCost["pneumoinrio"] = 10;
var EventsAbesDn = ["14seminarioabes-dn", "abes-dn", "sigpeeabes-dn", "silubesaabes-dn", "abes2021"];
CenterCost["14seminarioabes-dn"] = 4;
CenterCost["abes-dn"] = 8;
CenterCost["abes2021"] = 9;
CenterCost["silubesaabes-dn"] = 11;
CenterCost["sigpeeabes-dn"] = 12;
var EventsSbpt = ["sbpt", "intersticio2021", "cnap2021", "cnped2021", "sbptvirtual2021", "asmagrave", "intersticio2022", "radiopatologia2022", "radiopatologia2023", "radiopatologia2024"];
CenterCost["sbpt"] = 196;
CenterCost["sbptvirtual2021"] = 286;
CenterCost["intersticio2021"] = 308;
CenterCost["cnap2021"] = 309;
CenterCost["cnped2021"] = 311;
CenterCost["asmagrave"] = 323;
CenterCost["intersticio2022"] = 324;
CenterCost["radiopatologia2022"] = 329;
CenterCost["radiopatologia2023"] = 367;
CenterCost["radiopatologia2024"] = 377;
var EventsTecnomkt = [
  "tecnomkt",
  "cbed",
  "encontrocvbpsp",
  "climaterio2021",
  "queimaduras2021",
];
CenterCost["tecnomkt"] = 3;
CenterCost["encontrocvbpsp"] = 6;
CenterCost["climaterio2021"] = 8;
CenterCost["queimaduras2021"] = 7;
var EventsAssobrafir = ["assobrafircursocovid19", "nacionalassobrafir2021"];
CenterCost["assobrafircursocovid19"] = 5;
CenterCost["nacionalassobrafir2021"] = 11;
var EventsSobrafo = [
  "sobrafo",
  "seminariossobrafo",
  "seminariossobrafo03072021",
  "seminariosobrafo4"
];
CenterCost["sobrafo"] = 5;
CenterCost["seminariossobrafo03072021"] = 9;
CenterCost["seminariossobrafo"] = 10;
CenterCost["seminariosobrafo4"] = 11;
var EventsSbtmo = ['regionalsbtmoparana'
  , "encontrosbtmo"
  , "regionalsbtmobrasilia"
  , "sbtmo", "regionalsbtmobahia"
  , "forumterapiacelular", "encontromultiregionalsbtmo"
  , "regionalsbtmominasgerais", "regionalsbtmogoiania",
  "iiencontromultiregionalsbtmo", "regionalsbtmofortaleza",
  "regionalsbtmoriodejaneiro", "regionalsbtmocaipira", "iiiencontromultiregionalsbtmo"];
CenterCost["sbtmo"] = 3;
CenterCost["encontrosbtmo"] = 4;
CenterCost["regionalsbtmobrasilia"] = 5;
CenterCost["regionalsbtmobahia"] = 6;
CenterCost["forumterapiacelular"] = 7;
CenterCost["encontromultiregionalsbtmo"] = 9;
CenterCost["regionalsbtmominasgerais"] = 12;
CenterCost["regionalsbtmogoiania"] = 14;
CenterCost['regionalsbtmoparana'] = 15;
CenterCost['iiencontromultiregionalsbtmo'] = 18;
CenterCost['regionalsbtmofortaleza'] = 19;
CenterCost['regionalsbtmoriodejaneiro'] = 20;
CenterCost['regionalsbtmocaipira'] = 21;
CenterCost['iiiencontromultiregionalsbtmo'] = 22;
var EventsSbem = ["ecbaem2021"]
CenterCost["ecbaem2021"] = 3;
CenterCost["sbem"] = 4;
CenterCost["ebt2022"] = 71;
CenterCost['cbem2022'] = 70;
CenterCost['cbaem2023'] = 78;
CenterCost['cbem2024'] = 87;
var EventsCbc = ["cbc", "cbc2021"]
CenterCost["cbc"] = 30;
CenterCost["cbc2021"] = 32;
var EventsApresentacao = ["eventodemo1", "eventodemo2", "eventodemo3"]
CenterCost["eventodemo1"] = 121;
CenterCost["eventodemo2"] = 122;
CenterCost["eventodemo3"] = 123;
CenterCost["xvcboom"] = 154;
CenterCost["isicbrazil2023"] = 3;
CenterCost["fbgcursos"] = 4;

var c = window.location.hostname.split(".")[0];
var env = window.location.hostname.split(".")[2];
c = c === "localhost" ? "radiopatologia2024" : c;
c = c === "iah2021" ? "abas" : c;
c = c === "sobope2022" ? "sobope" : c;
c = c === "immuno2021" ? "sbi" : c;
c = c === "nefrologia2021" ? "smn" : c;
c = c === "medtropplay" ? "bureaudeeventos" : c;
c = c === "wft2021" ? "newcbr" : c;
c = c === "riopipeline2021" ? "ibp" : c;
c = c === "fita2021" ? "pauta" : c;
c = c === "falan2022" ? "pauta" : c;
c = c === "simposiosaneamento" ? "abessp" : c;
c = c === "viicombined" ? "aborl" : c;
c = c === "aborl2022" ? "aborl" : c;
c = c === "simposiodedermatologiapediatrica" ? "sbd" : c;
c = c === "14seminarioabes-dn" ? "abes-dn" : c;
c = c === "xvcboom" ? "aboom" : c;
c = c === "isicbrazil2023" ? "isic" : c;
c = c === "higiexpoecongressoabrafac" ? "abralimp" : c;
c = c === "apr" ? "abralimp" : c;
c = c === "sbem" ? "cbem" : c;
c = c === "ecbaem2021" ? "sbem" : c;
c = c === "ebt2022" ? "sbem" : c;
c = c === "cbem2022" ? 'sbem' : c;
c = c === "cbaem2023" ? 'sbem' : c;
c = c === "cbem2024" ? 'sbem' : c;
c = c === "regionalsbtmoriodejaneiro" ? 'sbtmo' : c;
c = c === "regionalsbtmocaipira" ? 'sbtmo' : c;
c = c === "fbgcursos" ? 'fbg' : c;
c = EventsTecnomkt.includes(c) ? "tecnomkt" : c;
c = EventsSbpt.includes(c) ? "sbpt" : c;
c = EventsAbesDn.includes(c) ? "abes-dn" : c;
c = EventsAssobrafir.includes(c) ? "assobrafir" : c;
c = EventsSobrafo.includes(c) ? "sobrafo" : c;
c = EventsSbtmo.includes(c) ? "sbtmo" : c;
c = EventsCbc.includes(c) ? "cbc2021" : c;
c = EventsMezcla.includes(c) ? "mezcla" : c;
c = EventsAsbran.includes(c) ? "asbraneventos" : c;
c = EventsApresentacao.includes(c) ? "demo" : c;

const BaseUrlAc = (c) => {
  if (c === "demo" && env === "test") {
    return "https://demo.ws.teste.itarget.com.br/";
  } else {
    return "https://" + c + ".ws.itarget.com.br/";
  }
};

const Config = {
  baseUrl: BaseUrlAc(c),
  urlWs: (c === 'sbem' ? "websocket.ws.itarget.com.br" : c + ".websocket.ws.itarget.com.br"),
  wsPort: null,
  wsKey: c,
  absolutePathImagem: "https://images.icongressolive.itarget.com.br",
  clientIcongresso: c,
  // urlWs:window.location.hostname,
  // wsPort:'6001',
  // wsKey: 'demo',
  urlSocket: "https://socket.itarget.com.br:8017/",
  /**
   * centroCustoId: 8 <-- para trabalhar local
   * baseUrl: 'https://tecnomkt.ws.itarget.com.br/'
   */
  centroCustoId: CenterCost[window.location.hostname.split(".")[0]],
  liteLogin: ["fortedecopacabana"].includes(window.location.hostname.split(".")[0]) ? true : false,
  client: window.location.hostname.split(".")[0] === "localhost" ? "fbgcursos" : window.location.hostname.split(".")[0],
  setTimeExpiredHour: 13,
  nameAuthLoggedToken: `${window.location.hostname.split(".")[0]}_auth@isLoggedToken`,
  messageSessionExpired: Translate({ transRef: "translation.Sua sessao expirou, por favor, faca login novamente.", }).props.children,
  messageExit: Translate({ transRef: "translation.Ate breve! Caso deseje continuar assistindo faca seu login novamente", }).props.children,
};
document.title = Config.client === "sbtmo" ? "Regional Sbtmo Edição Rio de Janeiro" : "iCongresso - Itarget Tecnologia";
document.title = Config.client === "cbp" ? "XXXVIII Congresso Brasileiro de Psiquiatria - CBP" : document.title;
document.title = Config.client === "pneumoinrio" ? "XVIII CONGRESSO DE PNEUMOLOGIA E TISIOLOGIA DO ESTADO DO RIO DE JANEIRO" : document.title;
document.title = Config.client === "asmagrave" ? "CURSO DE CAPACITAÇÃO EM ASMA GRAVE" : document.title;
document.title = Config.client === "radiopatologia2022" ? "Curso Nacional de Rádio-Patologia Pulmonar" : document.title;
document.title = Config.client === "radiopatologia2023" ? "Curso Nacional de Rádio-Patologia Pulmonar - 05 e 06/05/2023" : document.title;
document.title = Config.client === "intersticio2022" ? " XV Curso Nacional de Doenças Intersticiais Pulmonares e VI Jornada Paulista de Doenças Intersticiais Pulmonares" : document.title;
document.title = Config.client === "wft2021" ? "World Forum Trends: In Diagnostic Imaging" : document.title;
document.title = Config.client === "riopipeline2021" ? "Rio Pipeline Conference & Exhibition" : document.title;
document.title = Config.client === "rog22" ? "ROG Virtual 2022" : document.title;
document.title = Config.client === "confelanyd" ? "XVIII CONGRESSO CONFELANYD 2021" : document.title;
document.title = Config.client === "fita2021" ? "Feira Internacional de Turismo da Amazônia - FITA 2021" : document.title;
document.title = Config.client === "simposiosaneamento" ? "Simpósio Fornecedores do Saneamento 2021" : document.title;
document.title = Config.client === "demo" ? "Plataforma de Eventos Online - iTarget Tecnologia" : document.title;
document.title = Config.client === "viicombined" ? "VII Combined Meeting" : document.title;
document.title = Config.client === "sobope2022" ? "STATE OF THE ART EM ONCOLOGIA PEDIÁTRICA" : document.title;
export default Config;